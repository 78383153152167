<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Demo Page</div>
        <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
        </div>
    </div>
    <div class="company-detail-box-outer" style="height: calc(100vh - 141px)">
        <div class="company-box-outer">
            <div class="mb-3">
                976XXXX226
            </div>
            <div class="mb-3">
                {{ maskedEmail(emailvalue) }}
            </div>
            <div class="mb-3">
                {{ maskedNumber(originalNumber) }}
            </div>
        </div>
    </div>
    
</template>
<script>
export default {
    data() {
        return {
            mobileno: "9762468226",
            emailvalue: "tushar.sk55@gmail.com",
            originalNumber: '9762468226',
        };
    },
    computed: {
    },
    watch: {
    },
    methods: {
        maskedNumber(getvalue) {
            const maskedDigits = 'X'.repeat(getvalue.length - 6);
            const FirstThreeDigits = getvalue.slice(0, 3);
            const lastThreeDigits = getvalue.slice(-3);
            return FirstThreeDigits + maskedDigits + lastThreeDigits;
        },
        maskedEmail(getvalue) {
            const [name, domain] = getvalue.split('@');
            return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
        }
    },
};
</script>
<style>
.company-detail-box-outer {
    position: relative;
    height: 525px;
    overflow: auto;
}

.company-box-outer {
    padding: 16px 16px 0;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    margin-top: 20px;
    height: 400px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
</style>
